import React from 'react';
import { Modal, Row, Col, message } from 'antd';
import { Formik } from 'formik';
import { Form, ResetButton, SubmitButton, Input } from 'formik-antd';
import * as Yup from 'yup';

import { createPermission, updatePermission } from 'api/permissions';

const PermissionSchema = Yup.object().shape({
    permissionValue: Yup.string()
        .required('Permission is required')
});

const permissionModalModes = {
    ADD: 'New',
    EDIT: 'Update'
};

const CreatePermissionModal = (props) => {
    const submitPermission = async (payload) => {
        const { uniqueId, permissionValue, name } = payload;

        try {
            if (props.mode === permissionModalModes.ADD) {
                await createPermission(payload);
                message.success('Permission successfully added', 2);
            } else {
                await updatePermission(uniqueId, {
                    permissionValue,
                    name: name || ''
                });
                message.success('Permission successfully updated', 2);
            }

            if (props?.afterSubmit) {
                props.afterSubmit();
            }
        } catch (err) {
            message.error('Error occurred while creating permission', 2);
        }
    };

    return <Modal
                title={`${props.mode || 'New'} Permission`}
                {...props}
                footer={null}
            >
                <Formik
                    initialValues={props.initialValues || {
                        name: '',
                        permissionValue: ''
                    }}
                    validationSchema={PermissionSchema}
                    onSubmit={(values) => {
                        submitPermission(values);
                    }}
                >
                    <Form
                        layout='vertical'
                        colon={true}
                    >
                        <Form.Item
                            className='mb-3'
                            label='Name'
                            name='name'
                        >
                            <Input name='name' placeholder='Enter permission name' />
                        </Form.Item>
                        <Form.Item 
                            className='mb-5'
                            label='Permission'
                            name='permissionValue'
                        >
                            <Input name='permissionValue' placeholder='e.g. appointment:list:columns' />
                        </Form.Item>
                        <Row 
                            gutter={4} 
                            className='d-flex justify-content-end'
                        >
                            <Col>
                                <ResetButton>Reset</ResetButton>
                            </Col>
                            <Col>
                                <SubmitButton>Save</SubmitButton>
                            </Col>
                        </Row>
                    </Form>
                </Formik>
            </Modal>
};

export default CreatePermissionModal;
